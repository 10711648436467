@tailwind components;
@tailwind utilities;

@layer components {
  #hero {
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    --white: #fff;
    --overlay-color: yellow;
    --gradient: linear-gradient(
      to top left,
      hsla(170, 4%, 30%, 0.1),
      hsla(59, 90%, 49%, 0.475),
      hsla(39, 100%, 50%, 0.475),
      hsla(0, 100%, 50%, 0.4)
    );

    .parent {
      position: relative;
      height: 50vh;
      width: 100%;
      overflow: hidden;
      background-image: url("../assets/images/hero/bg-hero.webp");
      background-size: cover;
      background-position-y: 67%;
      display: flex;
      justify-content: center;
      place-items: center;
    }

    .clif {
      background-image: url("../assets/images/hero/clif.webp");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 603px;
      aspect-ratio: 500/333;
      opacity: 0.01;
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      animation: fade-in 1s ease-in-out 1s forwards;
    }

    .person {
      opacity: 0.01;
      background-image: url("../assets/images/hero/person.webp");
      background-repeat: no-repeat;
      background-size: contain;
      aspect-ratio: 6/19;
      background-position: center left;
      position: absolute;
      bottom: 120px;
      right: 180px;
      width: 80px;
      animation: fade-in 1s ease-in-out 2s forwards;
    }

    .sun {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: white;
      background: radial-gradient(var(--white), var(--overlay-color));
      position: absolute;
      bottom: 50%;
      right: 50%;
      opacity: 0.01;
      animation: sunAppears 2s linear 2s forwards;
    }

    .logo {

      height: 50%;
      position: relative;
      z-index: 1;
      opacity: 0.01;
      animation: showingLogo 2.1s ease-in-out 3s forwards;

      background: rgba(16, 16, 16, 0.5);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(6.3px);
      -webkit-backdrop-filter: blur(6.3px);
      border: 1px solid rgba(255, 255, 255, 0.26);

      display: flex;
      justify-content: center;
    }

    .logo img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 6/19;
    }

    .bird {
      background-image: url("../assets/images/hero/bird-cells-new.svg");
      filter: invert(34%) sepia(55%) saturate(427%) hue-rotate(141deg)
        brightness(93%) contrast(91%);
      background-size: auto 100%;
      width: 88px;
      height: 125px;
      will-change: background-position;
      animation-name: fly-cycle;
      animation-timing-function: steps(10);
      animation-iteration-count: infinite;
    }

    .bird-one {
      animation-duration: 1s;
      animation-delay: -0.5s;
    }
    .bird-two {
      animation-duration: 0.9s;
      animation-delay: -0.75s;
    }
    .bird-three {
      animation-duration: 1.25s;
      animation-delay: -0.25s;
    }
    .bird-four {
      animation-duration: 1.1s;
      animation-delay: -0.5s;
    }

    .bird-container {
      position: absolute;
      top: 10%;
      transform: scale(0) translateX(-10vw);
      will-change: transform;
      width: 50vw;
      animation-name: fly-right-two;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    .bird-container-one {
      animation-duration: 15s;
      animation-delay: 0s;
    }

    .bird-container-two {
      animation-duration: 16s;
      animation-delay: 1s;
    }

    .bird-container-three {
      animation-duration: 14.6s;
      animation-delay: 9.5s;
    }

    .bird-container-four {
      animation-duration: 16s;
      animation-delay: 10.25s;
    }
  }
}
@layer utilities {
  @keyframes fly-cycle {
    100% {
      background-position: -900px 0;
    }
  }

  @keyframes fly-right-one {
    0% {
      transform: scale(0.3) translateX(-10vw);
    }

    10% {
      transform: translateY(2vh) translateX(10vw) scale(0.4);
    }

    20% {
      transform: translateY(0vh) translateX(30vw) scale(0.5);
    }

    30% {
      transform: translateY(4vh) translateX(50vw) scale(0.6);
    }

    40% {
      transform: translateY(2vh) translateX(70vw) scale(0.6);
    }

    50% {
      transform: translateY(0vh) translateX(90vw) scale(0.6);
    }

    60% {
      transform: translateY(0vh) translateX(95vw) scale(0.6);
    }

    100% {
      transform: translateY(0vh) translateX(95vw) scale(0.6);
    }
  }

  @keyframes fly-right-two {
    0% {
      transform: translateY(-2vh) translateX(-10vw) scale(0.5);
    }

    10% {
      transform: translateY(0vh) translateX(10vw) scale(0.4);
    }

    20% {
      transform: translateY(-4vh) translateX(30vw) scale(0.6);
    }

    30% {
      transform: translateY(1vh) translateX(50vw) scale(0.45);
    }

    40% {
      transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
    }

    50% {
      transform: translateY(0vh) translateX(90vw) scale(0.45);
    }

    51% {
      transform: translateY(0vh) translateX(95vw) scale(0.45);
    }

    100% {
      transform: translateY(0vh) translateX(101vw) scale(0.6);
    }
  }

  @keyframes showingLogo {
    100% {
      opacity: 1;
      filter: brightness(1.75);
    }
  }

  @keyframes sunAppears {
    100% {
      transform: translate(-80%, -216%) scale(1.15);
      opacity: 0.75;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0.01;
    }
    100% {
      opacity: 1;
    }
  }
}
