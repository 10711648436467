@tailwind components;

@layer components {
  #welcome {
    .location-links ul>li::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-top: 0px;
      margin-bottom: -2px;
      margin-right: 3px;
      mask: url("../assets/images/map-icon.svg");
      mask-size: contain;
      @apply bg-[#274D86] dark:bg-pageBg;
    }
  }
}