#contact {
  .body {
    @apply mt-3 grid place-items-center gap-x-4 gap-y-28 pb-8 
    sm:grid-cols-2
    md:px-4 lg:grid-cols-3 xl:grid-cols-3
    max-w-7xl;
  }
  .glass-card {
    @apply col-span-full w-full rounded-xl border-2
            border-action bg-pageBg/50 p-3 text-center leading-relaxed backdrop-blur dark:bg-componentBgDark;
  }
  .map-card {
    @apply relative h-full w-72 rounded-lg border border-solid border-action bg-componentBg/70 p-4 pt-2 text-center antialiased shadow-xl backdrop-blur
      transition-all duration-200 ease-in-out hover:scale-110 hover:bg-componentBg dark:bg-componentBgDark/70 mx-3;
    backface-visibility: hidden;
    transform: perspective(1px) translateZ(0);

    .more-info {
      @apply mt-2 text-lg font-bold;
    }
    .map-icons {
      @apply mt-2 flex items-center justify-around;
    }
    .info {
      backface-visibility: hidden;
      transform: translateZ(0);
      -webkit-font-smoothing: subpixel-antialiased;
      min-height: 8rem;
      @apply leading-6;
      .name {
        @apply mx-0 my-2 text-xl font-bold;
      }
    }
    .pic {
      @apply m-auto -mt-20
      h-36 w-36
      justify-self-center
      overflow-hidden rounded-full border
      border-solid border-action
      bg-highlight;
      img {
        @apply w-36;
      }
    }
  }
}
