@tailwind base;

@layer base {
  html,body {
    scroll-behavior: smooth;    
    scroll-padding-top: var(--scroll-margin, 40px);
    font-family: 'Raleway', sans-serif;
    transition: background-color 2s;
  }
 

  * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  @counter-style blue-arrow {
    system: cyclic;
    symbols: ➡️;
    suffix: " ";
  }
  section {
    @apply border-b border-b-tertiary
     px-1 pb-5 pt-2;
    .section-header {
      @apply text-center block text-2xl
       font-bold text-action dark:text-pageBg
    }
  }


}
