*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

html, body {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-margin, 40px);
  font-family: Raleway, sans-serif;
  transition: background-color 2s;
}

* {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

@counter-style blue-arrow {
  system: cyclic;
  symbols: ➡️;
  suffix: " ";
}

section {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: rgb(122 148 196 / var(--tw-border-opacity));
  padding: .5rem .25rem 1.25rem;
}

section .section-header {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(50 96 169 / var(--tw-text-opacity));
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  display: block;
}

section .section-header:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(246 245 244 / var(--tw-text-opacity));
}

#welcome .location-links ul > li:before {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(39 77 134 / var(--tw-bg-opacity));
  width: 1em;
  height: 1em;
  margin-top: 0;
  margin-bottom: -2px;
  margin-right: 3px;
  display: inline-block;
  -webkit-mask: url("map-icon.bf7520bf.svg") 0 0 / contain;
  mask: url("map-icon.bf7520bf.svg") 0 0 / contain;
}

#welcome .location-links ul > li:is(.dark *):before {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 244 / var(--tw-bg-opacity));
}

nav li a {
  display: inline-block;
  position: relative;
}

nav li a:after {
  transform-origin: center;
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  width: 100%;
  height: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(100 140 196 / var(--tw-bg-opacity));
  opacity: 1;
  transition-property: opacity 250, transform 250;
  content: "";
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

nav li.active a:after {
  opacity: 1;
  transform: scale(1);
}

#flyout-menu {
  display: none;
  box-shadow: 0 3px 20px #0003, 0 3px 30px #0000001f;
}

.menu-is-open #flyout-menu {
  animation: .5s alternate forwards openMenu;
  display: block;
}

.menu-is-close #flyout-menu {
  animation: .5s alternate forwards closeMenu;
  display: block;
}

.hamburger-menu-layer {
  --tw-bg-opacity: 1;
  background-color: rgb(50 96 169 / var(--tw-bg-opacity));
  height: .5rem;
  margin-bottom: .25rem;
  transition: all .3s ease-in-out;
}

.menu-is-open #flyout-menu-btn .hamburger-menu-layer:first-child {
  transform: translateY(13px)rotate(45deg);
}

.menu-is-open #flyout-menu-btn .hamburger-menu-layer:nth-child(2) {
  opacity: 0;
}

.menu-is-open #flyout-menu-btn .hamburger-menu-layer:nth-child(3) {
  transform: translateY(-10px)rotate(-45deg);
}

@keyframes openMenu {
  0% {
    clip-path: inset(0 0 99% 100%);
  }

  100% {
    clip-path: inset(0 -15% 0 -61%);
  }
}

@keyframes closeMenu {
  0% {
    clip-path: inset(0 -15% 0 -61%);
  }

  100% {
    clip-path: inset(0 0 99% 100%);
  }
}

@media (width >= 768px) {
  .md\:order-last {
    order: 9999;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-\[15lh\] {
    height: 15lh;
  }

  .md\:h-\[16lh\] {
    height: 16lh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:w-\[30vw\] {
    width: 30vw;
  }
}

#hero {
  --white: #fff;
  --overlay-color: yellow;
  --gradient: linear-gradient(to top left, #49504f1a, #edea0c79, #ffa60079, #f006);
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

#hero .parent {
  background-image: url("bg-hero.2d658b40.webp");
  background-position-y: 67%;
  background-size: cover;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 50vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

#hero .clif {
  aspect-ratio: 500 / 333;
  opacity: .01;
  background-image: url("clif.7fc3be49.webp");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 603px;
  width: 100%;
  height: 100%;
  animation: 1s ease-in-out 1s forwards fade-in;
  position: absolute;
  bottom: 0;
  right: 0;
}

#hero .person {
  opacity: .01;
  aspect-ratio: 6 / 19;
  background-image: url("person.79219846.webp");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  animation: 1s ease-in-out 2s forwards fade-in;
  position: absolute;
  bottom: 120px;
  right: 180px;
}

#hero .sun {
  background-color: #fff;
  background: radial-gradient(var(--white), var(--overlay-color));
  opacity: .01;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 2s linear 2s forwards sunAppears;
  position: absolute;
  bottom: 50%;
  right: 50%;
}

#hero .logo {
  z-index: 1;
  opacity: .01;
  -webkit-backdrop-filter: blur(6.3px);
  background: #10101080;
  border: 1px solid #ffffff42;
  border-radius: 16px;
  justify-content: center;
  height: 50%;
  animation: 2.1s ease-in-out 3s forwards showingLogo;
  display: flex;
  position: relative;
  box-shadow: 0 4px 30px #0000004d;
}

#hero .logo img {
  object-fit: contain;
  aspect-ratio: 6 / 19;
  width: 100%;
}

#hero .bird {
  filter: invert(34%) sepia(55%) saturate(427%) hue-rotate(141deg) brightness(93%) contrast(91%);
  will-change: background-position;
  background-image: url("bird-cells-new.07beb9f7.svg");
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  animation-name: fly-cycle;
  animation-timing-function: steps(10, end);
  animation-iteration-count: infinite;
}

#hero .bird-one {
  animation-duration: 1s;
  animation-delay: -.5s;
}

#hero .bird-two {
  animation-duration: .9s;
  animation-delay: -.75s;
}

#hero .bird-three {
  animation-duration: 1.25s;
  animation-delay: -.25s;
}

#hero .bird-four {
  animation-duration: 1.1s;
  animation-delay: -.5s;
}

#hero .bird-container {
  will-change: transform;
  width: 50vw;
  animation-name: fly-right-two;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10%;
  transform: scale(0)translateX(-10vw);
}

#hero .bird-container-one {
  animation-duration: 15s;
  animation-delay: 0s;
}

#hero .bird-container-two {
  animation-duration: 16s;
  animation-delay: 1s;
}

#hero .bird-container-three {
  animation-duration: 14.6s;
  animation-delay: 9.5s;
}

#hero .bird-container-four {
  animation-duration: 16s;
  animation-delay: 10.25s;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(.3)translateX(-10vw);
  }

  10% {
    transform: translateY(2vh)translateX(10vw)scale(.4);
  }

  20% {
    transform: translateY(0)translateX(30vw)scale(.5);
  }

  30% {
    transform: translateY(4vh)translateX(50vw)scale(.6);
  }

  40% {
    transform: translateY(2vh)translateX(70vw)scale(.6);
  }

  50% {
    transform: translateY(0)translateX(90vw)scale(.6);
  }

  60% {
    transform: translateY(0)translateX(95vw)scale(.6);
  }

  100% {
    transform: translateY(0)translateX(95vw)scale(.6);
  }
}

@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh)translateX(-10vw)scale(.5);
  }

  10% {
    transform: translateY(0)translateX(10vw)scale(.4);
  }

  20% {
    transform: translateY(-4vh)translateX(30vw)scale(.6);
  }

  30% {
    transform: translateY(1vh)translateX(50vw)scale(.45);
  }

  40% {
    transform: translateY(-2.5vh)translateX(70vw)scale(.5);
  }

  50% {
    transform: translateY(0)translateX(90vw)scale(.45);
  }

  51% {
    transform: translateY(0)translateX(95vw)scale(.45);
  }

  100% {
    transform: translateY(0)translateX(101vw)scale(.6);
  }
}

@keyframes showingLogo {
  100% {
    opacity: 1;
    filter: brightness(1.75);
  }
}

@keyframes sunAppears {
  100% {
    opacity: .75;
    transform: translate(-80%, -216%)scale(1.15);
  }
}

@keyframes fade-in {
  0% {
    opacity: .01;
  }

  100% {
    opacity: 1;
  }
}

@media (width >= 768px) {
  .md\:order-last {
    order: 9999;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-\[15lh\] {
    height: 15lh;
  }

  .md\:h-\[16lh\] {
    height: 16lh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:w-\[30vw\] {
    width: 30vw;
  }
}

#physicians {
  margin: 0;
  padding: 0;
}

#physicians .physiciansParent {
  place-items: center;
  gap: 0;
  padding-left: .75rem;
  display: grid;
}

#physicians .physiciansParent:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(22 25 37 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 37 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  #physicians .physiciansParent {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  #physicians .physiciansParent {
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 1024px) {
  #physicians .physiciansParent {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  #physicians .physiciansParent {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (width >= 1536px) {
  #physicians .physiciansParent {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

#physicians .physiciansParent .physician {
  --tw-bg-opacity: 1;
  background-color: rgb(195 203 227 / var(--tw-bg-opacity));
  border-radius: .75rem;
  width: 300px;
  height: 9rem;
  margin-top: 8rem;
  margin-right: 1.25rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: linear;
}

#physicians .physiciansParent .physician:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

#physicians .physiciansParent .physician .physician-link {
  object-fit: cover;
  place-items: center;
  height: 100%;
  margin-top: 2.5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: grid;
}

#physicians .physiciansParent .physician .physician-link .img {
  --tw-translate-y: -70%;
  width: 150px;
  height: 150px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  object-fit: cover;
  background-size: cover;
  border-radius: 9999px;
  position: absolute;
}

#physicians .physiciansParent .physician .physician-link .img.logo {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 244 / var(--tw-bg-opacity));
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150px;
}

#physicians .physiciansParent .physician .info {
  justify-items: center;
  padding: 1.25rem;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: none;
}

#physicians .physiciansParent .physician .info .description .back-link {
  --tw-bg-opacity: 1;
  background-color: rgb(50 96 169 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(254 254 250 / var(--tw-text-opacity));
  border-radius: .75rem;
  margin-top: .75rem;
  padding: .5rem 1rem;
  text-decoration-line: none;
  display: none;
}

#physicians .physician:hover .img {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#physicians .physician:hover .bio-link {
  font-weight: 700;
}

#physicians .physician.expanding, #physicians .physician:target {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  margin: .5rem 0 0;
}

#physicians .physician.expanding .physician-link, #physicians .physician:target .physician-link {
  display: none;
}

#physicians .physician.expanding .info, #physicians .physician:target .info {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: center;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: grid;
}

#physicians .physician.expanding .info img, #physicians .physician:target .info img {
  object-fit: cover;
  object-position: top;
  border-radius: .5rem;
  width: 100vw;
  height: 100%;
  margin-bottom: 1rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 640px) {
  #physicians .physician.expanding .info img, #physicians .physician:target .info img {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  #physicians .physician.expanding .info img, #physicians .physician:target .info img {
    width: 50%;
  }
}

#physicians .physician.expanding .info .description .back-link, #physicians .physician:target .info .description .back-link {
  display: inline-block;
}

#contact .body {
  place-items: center;
  gap: 7rem 1rem;
  max-width: 80rem;
  margin-top: .75rem;
  padding-bottom: 2rem;
  display: grid;
}

@media (width >= 640px) {
  #contact .body {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  #contact .body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 1024px) {
  #contact .body {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  #contact .body {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

#contact .glass-card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  text-align: center;
  --tw-backdrop-blur: blur(8px);
  width: 100%;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #f6f5f480;
  border-radius: .75rem;
  grid-column: 1 / -1;
  padding: .75rem;
  line-height: 1.625;
}

#contact .glass-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

#contact .map-card {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  width: 18rem;
  height: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #c3cbe3b3;
  border-radius: .5rem;
  margin-left: .75rem;
  margin-right: .75rem;
  padding: .5rem 1rem 1rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

#contact .map-card:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(195 203 227 / var(--tw-bg-opacity));
}

#contact .map-card:is(.dark *) {
  background-color: #25293db3;
}

#contact .map-card {
  backface-visibility: hidden;
  transform: perspective(1px)translateZ(0);
}

#contact .map-card .more-info {
  margin-top: .5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#contact .map-card .map-icons {
  justify-content: space-around;
  align-items: center;
  margin-top: .5rem;
  display: flex;
}

#contact .map-card .info {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 8rem;
  line-height: 1.5rem;
  transform: translateZ(0);
}

#contact .map-card .info .name {
  margin: .5rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#contact .map-card .pic {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(132 156 204 / var(--tw-bg-opacity));
  border-radius: 9999px;
  justify-self: center;
  width: 9rem;
  height: 9rem;
  margin: -5rem auto auto;
  overflow: hidden;
}

#contact .map-card .pic img {
  width: 9rem;
}

.service-card {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  max-width: 24rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.25rem;
}

.service-card:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

@media (width >= 768px) {
  .service-card {
    width: 100%;
    max-width: 48rem;
  }
}

.service-card .image img {
  object-fit: cover;
  object-position: right;
  width: 100%;
  max-height: 22rem;
}

@media (width >= 768px) {
  .service-card .image img {
    max-height: 12rem;
  }
}

@media (width >= 1024px) {
  .service-card .image img {
    max-height: 24rem;
  }
}

.service-card .explanation {
  font-weight: 500;
  line-height: 1.75rem;
}

.service-card .explanation h2 {
  text-align: center;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.service-card .explanation h2:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.service-card .explanation p {
  white-space: pre-line;
}

.service-card:hover {
  --tw-scale-x: 1.01;
  --tw-scale-y: 1.01;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(195 203 227 / var(--tw-bg-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.service-card:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

@media (width >= 1024px) {
  .service-card:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (width >= 768px) {
  .md\:order-last {
    order: 9999;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-\[15lh\] {
    height: 15lh;
  }

  .md\:h-\[16lh\] {
    height: 16lh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:w-\[30vw\] {
    width: 30vw;
  }
}

#locations {
  background-image: url("pexels-stephan-seeber-1261728.c3be26bc.webp");
  background-position: 50%;
  background-size: cover;
}

#locations .body {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-radius: .5rem;
  place-items: center;
  gap: 7rem 1rem;
  width: 100vw;
  margin-top: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

@media (width >= 640px) {
  #locations .body {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 1024px) {
  #locations .body {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

#locations .glass-card {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  text-align: center;
  --tw-backdrop-blur: blur(8px);
  width: 100%;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #f6f5f480;
  border-radius: .75rem;
  grid-column: 1 / -1;
  padding: .75rem;
  line-height: 1.625;
}

#locations .glass-card:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

#locations .map-card {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  width: 18rem;
  height: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #c3cbe3b3;
  border-radius: .5rem;
  margin-left: .75rem;
  margin-right: .75rem;
  padding: .5rem 1rem 1rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

#locations .map-card:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(195 203 227 / var(--tw-bg-opacity));
}

#locations .map-card:is(.dark *) {
  background-color: #25293db3;
}

#locations .map-card {
  backface-visibility: hidden;
  transform: perspective(1px)translateZ(0);
}

#locations .map-card .more-info {
  margin-top: .5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#locations .map-card .map-icons {
  justify-content: space-around;
  align-items: center;
  margin-top: .5rem;
  display: flex;
}

#locations .map-card .info {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 8rem;
  line-height: 1.5rem;
  transform: translateZ(0);
}

#locations .map-card .info .name {
  margin: .5rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#locations .map-card .pic {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50 96 169 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(132 156 204 / var(--tw-bg-opacity));
  border-radius: 9999px;
  justify-self: center;
  width: 9rem;
  height: 9rem;
  margin: -5rem auto auto;
  overflow: hidden;
}

#locations .map-card .pic img {
  width: 9rem;
}

#locations .map-card:has(.pic:target) {
  color: #d9d0d0;
  background-color: #433e3ee6;
}

#back-to-top-page {
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  color: #fff;
  background-color: #3260a9;
  border-radius: 10px;
  width: 80px;
  height: 85px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  display: inline-block;
  position: fixed;
  bottom: 50px;
  right: 30px;
}

#back-to-top-page:before {
  content: "^";
  color: #fff;
  margin-bottom: -.5em;
  font-family: FontAwesome;
  font-size: 2em;
  font-style: normal;
  font-weight: normal;
  display: block;
}

#back-to-top-page:active {
  background-color: #555;
}

#back-to-top-page.show {
  opacity: 1;
  visibility: visible;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-50 {
  z-index: 50;
}

.order-first {
  order: -9999;
}

.col-span-full {
  grid-column: 1 / -1;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 1.25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-11px\] {
  margin-top: -11px;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-28 {
  height: 7rem;
}

.h-auto {
  height: auto;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-28 {
  width: 7rem;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-full {
  width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-help {
  cursor: help;
}

.list-\[blue-arrow\] {
  list-style-type: blue-arrow;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-end {
  place-content: end;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-items-center {
  justify-items: center;
}

.gap-5 {
  gap: 1.25rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.bg-componentBg {
  --tw-bg-opacity: 1;
  background-color: rgb(195 203 227 / var(--tw-bg-opacity));
}

.bg-highlight {
  --tw-bg-opacity: 1;
  background-color: rgb(132 156 204 / var(--tw-bg-opacity));
}

.bg-pageBg {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 244 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-loose {
  line-height: 2;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#274D86\] {
  --tw-text-opacity: 1;
  color: rgb(39 77 134 / var(--tw-text-opacity));
}

.text-action {
  --tw-text-opacity: 1;
  color: rgb(50 96 169 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-pageBgDark {
  --tw-text-opacity: 1;
  color: rgb(22 25 37 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tertiary {
  --tw-shadow-color: #7a94c4;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:text-action:hover {
  --tw-text-opacity: 1;
  color: rgb(50 96 169 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.dark\:flex:is(.dark *) {
  display: flex;
}

.dark\:hidden:is(.dark *) {
  display: none;
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.dark\:border-pageBgDark:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(22 25 37 / var(--tw-border-opacity));
}

.dark\:bg-componentBgDark:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 41 61 / var(--tw-bg-opacity));
}

.dark\:bg-gray-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:bg-pageBgDark:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 37 / var(--tw-bg-opacity));
}

.dark\:text-pageBg:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(246 245 244 / var(--tw-text-opacity));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:shadow-componentBgDark:is(.dark *) {
  --tw-shadow-color: #25293d;
  --tw-shadow: var(--tw-shadow-colored);
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

@media (width >= 768px) {
  .md\:order-last {
    order: 9999;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-\[15lh\] {
    height: 15lh;
  }

  .md\:h-\[16lh\] {
    height: 16lh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:w-\[30vw\] {
    width: 30vw;
  }
}

.\[\&\>\*\:focus\]\:scale-110 > :focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\:focus\]\:text-xl > :focus {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.\[\&\>\*\:focus\]\:underline > :focus {
  text-decoration-line: underline;
}

.\[\&\>\*\:hover\]\:scale-110 > :hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\:hover\]\:text-xl > :hover {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.\[\&\>\*\:hover\]\:underline > :hover {
  text-decoration-line: underline;
}

.\[\&\>\*\>h2\]\:my-3 > * > h2 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\[\&\>\*\>h2\]\:text-xl > * > h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.\[\&\>\*\>h2\]\:font-bold > * > h2 {
  font-weight: 700;
}

.\[\&\>\*\>h2\]\:text-action > * > h2 {
  --tw-text-opacity: 1;
  color: rgb(50 96 169 / var(--tw-text-opacity));
}

.\[\&\>\*\]\:grow-0 > * {
  flex-grow: 0;
}

.\[\&\>\*\]\:px-3 > * {
  padding-left: .75rem;
  padding-right: .75rem;
}

.\[\&\>\*\]\:py-5 > * {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.\[\&\>\*\]\:leading-8 > * {
  line-height: 2rem;
}

.\[\&\>\*\]\:transition-all > * {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[\&\>\*\]\:duration-100 > * {
  transition-duration: .1s;
}

.\[\&\>\*\]\:duration-75 > * {
  transition-duration: 75ms;
}

.\[\&\>\*\]\:ease-in-out > * {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[\&\>\*\]\:ease-linear > * {
  transition-timing-function: linear;
}

.\[\&\>a\]\:hover\:text-lg:hover > a {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\[\&\>li\>\*\]\:transition-all > li > * {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[\&\>li\>\*\]\:duration-75 > li > * {
  transition-duration: 75ms;
}

.\[\&\>li\>\*\]\:ease-linear > li > * {
  transition-timing-function: linear;
}

.\[\&\>li\>a\:hover\>img\]\:scale-125 > li > a:hover > img {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>li\>a\:hover\]\:text-lg > li > a:hover {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\[\&\>li\>a\:hover\]\:font-bold > li > a:hover {
  font-weight: 700;
}

.\[\&\>li\>a\:hover\]\:underline > li > a:hover {
  text-decoration-line: underline;
}

.\[\&\>li\>a\>img\]\:inline-block > li > a > img {
  display: inline-block;
}

.\[\&\>li\>a\]\:text-action > li > a {
  --tw-text-opacity: 1;
  color: rgb(50 96 169 / var(--tw-text-opacity));
}

.\[\&\>p\]\:mb-3 > p {
  margin-bottom: .75rem;
}

.\[\&\>svg\]\:hover\:scale-125:hover > svg {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>ul\]\:w-3\/4 > ul {
  width: 75%;
}
/*# sourceMappingURL=index.6df11e66.css.map */
