@tailwind components;
@tailwind utilities;

@layer components {

    .service-card {
        @apply max-w-sm md:max-w-3xl md:w-full bg-white border border-gray-600 rounded-lg shadow dark:bg-componentBgDark dark:border-gray-700 p-5;

        .image img {
            @apply w-full max-h-[22rem] md:max-h-48 lg:max-h-96 object-cover object-right;
        }

        .explanation {
            @apply font-medium leading-7;

            h2 {
                @apply mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center;
            }

            p {
                @apply whitespace-pre-line;
            }
        }

    }

    .service-card:hover {
        @apply scale-[1.01] lg:scale-105 bg-componentBg transition-all dark:bg-componentBgDark;
    }
}