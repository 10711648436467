@tailwind components;
@tailwind utilities;

@layer components {
  nav li a {
    @apply relative inline-block;
  }

  
  nav li a::after {
    @apply absolute
      bottom-0
      left-0
      h-0.5
      w-full
      origin-center
      scale-0
      transform
      bg-highlightBold
      opacity-100
      transition-[opacity_250,transform_250];
    content: "";
    transform: scale(0);
  }
  nav li.active a::after {
    opacity: 1;
    transform: scale(1);
  }

  #flyout-menu {
    box-shadow:
      0px 3px 20px rgba(0, 0, 0, 0.2),
      0px 3px 30px rgba(0, 0, 0, 0.12);
    display: none;
  }
  .menu-is-open #flyout-menu {
    animation: openMenu 0.5s alternate forwards;
    display: block;
  }

  .menu-is-close #flyout-menu {
    animation: closeMenu 0.5s alternate forwards;
    display: block;
  }

  .hamburger-menu-layer {
    transition: all 0.3s ease-in-out;
    @apply mb-1 h-2 bg-action;
  }
  .menu-is-open #flyout-menu-btn .hamburger-menu-layer:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }
  .menu-is-open #flyout-menu-btn .hamburger-menu-layer:nth-child(2) {
    opacity: 0;
  }
  .menu-is-open #flyout-menu-btn .hamburger-menu-layer:nth-child(3) {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
}

@layer utilities {
  @keyframes openMenu {
    0% {
      clip-path: inset(0 0 99% 100%);
    }
    100% {
      clip-path: inset(0 -15% 0 -61%);
    }
  }

  @keyframes closeMenu {
    0% {
      clip-path: inset(0 -15% 0 -61%);
    }
    100% {
      clip-path: inset(0 0 99% 100%);
    }
  }
}
