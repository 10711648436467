@tailwind components;

@layer components {
  #physicians {
    @apply m-0 p-0;
    .physiciansParent {
      @apply grid gap-0 md:gap-4 pl-3 md:px-4
      place-items-center
      sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5
      dark:bg-pageBgDark dark:border-pageBgDark;
      .physician {
        @apply w-[300px] h-36
        mt-32 rounded-xl
        mr-5 bg-componentBg dark:bg-componentBgDark
        transition-all duration-150 ease-linear;
        .physician-link {
          @apply h-full  object-cover
          grid place-items-center mt-10 px-1;
          .img {
            @apply w-[150px] h-[150px]
            rounded-full  absolute
            object-cover bg-cover
            transform -translate-y-[70%];
          }
          .img.logo {
            @apply bg-no-repeat bg-center border border-action bg-pageBg border-solid;
            background-size:150px;
          }
        }
        .info {
          @apply justify-items-center p-5
          transition-all duration-100 ease-in-out
          hidden;
          .description {
            .back-link {
              @apply no-underline  rounded-xl
          hidden
           px-4 py-2  text-onAction mt-3
           bg-action;
            }
          }
        }
      }
    }
    .physician:hover {
      .img {
        @apply transform scale-110 transition-all duration-200 ease-in-out shadow-2xl;
      }
      .bio-link {
        @apply font-bold;
      }
    }
    .physician.expanding,
    .physician:target{
      @apply m-0 mt-2 rounded-2xl w-full h-full;
      .physician-link {
        @apply hidden;
      }
      .info {
        @apply
        grid  grid-cols-1
        justify-items-center
        transition-all duration-100 ease-in-out
        ;
        img {
          @apply sm:grid-cols-1
      transition-all duration-300 ease-in-out h-full
      w-screen  md:w-1/2
      object-cover object-top
      mb-4 rounded-lg;
        }
        .description {
          .back-link {
            @apply inline-block ;
          }
        }
      }
    }
  }
}
